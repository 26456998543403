<template>
    <div class="impressum">
      <script v-html=navigationJsonLd() type="application/ld+json"/>

        <section class="hero is-primary">
            <div class="hero-body">
                <div class="container">
                    <h1 class="title has-text-centered is-size-1" style="font-family: 'Kaushan Script';">
                        Impressum
                    </h1>
                    <h2 class="subtitle is-italic has-text-centered">
                        Er aber sprach: Selig sind, die das Wort Gottes hören und bewahren. Lukas 11,28
                    </h2>
                </div>
            </div>
        </section>


        <section class="section">
            <div class="container">

                <h3 class="title is-4">Verantwortlich für die Website</h3>
                <p>
                    Christliche Gemeinde Karlsruhe e.V.<br/>
                    Reinhold-Frank-Str.44a<br/>
                    76133 Karlsruhe<br/><br/>

                    Vertreten durch die Vereinsvorstände:<br/>
                    David Poss, Gisbert Kleinhans und Patrick Späth <br/><br/>

                    E-Mail: <a href="mailto:kontakt@cg-karlsruhe.de">kontakt@cg-karlsruhe.de</a><br/><br/>

                    Eintragung im Vereinsregister.<br/>
                    Registergericht: Amtsgericht Mannheim<br/>
                    Registernummer: VR 100512
                </p>

                <h3 class="title is-5" style="margin-top:40px">Bilderquellen</h3>

                <p>
                    Das Bild auf der Startseite wurde von <a
                        href="https://unsplash.com/@benwhitephotography?utm_medium=referral&utm_campaign=photographer-credit&utm_content=creditBadge">Ben
                    White</a> erstellt und von unsplash.com bezogen.
                    <br/><br/>
                </p>


            </div>
        </section>

    </div>
</template>


<script>


export default {
  components: {
  },
  methods: {
    navigationJsonLd() {
      var jsonLdBreadcrumb= {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "CG Karlsruhe",
          "item": "https://www.cg-karlsruhe.de"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Impressum",
          "item": "https://www.cg-karlsruhe.de/impressum"
        }]
      };
      return jsonLdBreadcrumb;
    }
  }
}
</script>

