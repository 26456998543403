<template>
    <div class="about">
      <script v-html=navigationJsonLd() type="application/ld+json"/>
        <section class="hero is-primary">
            <div class="hero-body">
                <div class="container">
                    <h1 class="title has-text-centered is-size-1" style="font-family: 'Kaushan Script';">
                        Kontakt
                    </h1>
                    <h2 class="subtitle is-italic has-text-centered">
                        Er aber sprach: Selig sind, die das Wort Gottes hören und bewahren. Lukas 11,28
                    </h2>
                </div>
            </div>
        </section>


        <section class="section">
            <div class="container">


              <div v-bind:class="['notification', mailError ? 'is-danger' : 'is-success']" v-if="mailSent"
                   style="margin-top:20px">
                {{mailSentMessage}}
              </div>


              <form @submit.prevent="handleSubmit">
                <div class="field">
                  <label class="label">Name</label>
                  <div class="control">
                    <input v-bind:class="['input', nameIsEmpty ? 'is-danger' : '']"
                           type="text"
                           v-model="user.name"
                           v-on:keyup="validateName" placeholder="Dein Name">
                  </div>
                  <p class="help is-danger" v-if="nameIsEmpty">Den Datenschutzbestimmungen wurde nicht zugestimmt.</p>
                </div>

                <div class="field">
                  <label class="label">E-Mail</label>
                  <div class="control">
                    <input v-bind:class="['input', emailIsEmpty ? 'is-danger' : '']"
                           type="email" v-model="user.email"
                           v-on:keyup="validateEmail"
                           placeholder="deine.emailadresse@provider.de"
                           value="">
                  </div>
                  <p class="help is-danger" v-if="emailIsEmpty">Die E-Mail Adresse muss angegeben sein.</p>
                </div>


                <div class="field">
                  <label class="label">Empfänger</label>
                  <div class="control">
                    <div class="select">
                      <select v-model="user.empfaenger">
                        <option value="">Bitte Empfänger wählen</option>
                        <option value="gemeinde">Die Gemeinde</option>

                        <option value="andreas_herburger">Andreas Herburger (Ältester)</option>
                        <option value="christian_hirsch">Christian Hirsch (Ältester)</option>
                        <option value="otto_h">Otto Hubenschmid (Ältester)</option>
                        <option value="markus_remmel">Markus Remmel (Ältester)</option>

                        <option value="jungschar">Kids Ansprechpartner (8-13 Jahre)</option>
                        <option value="teens">Teenies Ansprechpartner (12-18 Jahre)</option>
                        <option value="ac">AC Junge Erwachsene (18-30 Jahre)</option>
                        <option value="hauskreis">Hauskreis Ansprechpartner (Erwachsene)</option>
                        <option value="frauen">Frauen</option>
                        <option value="senioren">Seniorenkreis Ansprechpartner (60+ Jahre)</option>
                        <option value="spielgruppe">Spielgruppe (Kids 0-6)</option>
                        <option value="cupofhope">Cup of Hope</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div class="field">
                  <label class="label">Nachricht</label>
                  <div class="control">
                        <textarea v-bind:class="['textarea', messageIsEmpty ? 'is-danger' : '']"
                                  v-model="user.message"
                                  v-on:keyup="validateMessage"
                                  placeholder="Hallo,"></textarea>
                  </div>
                  <p class="help is-danger" v-if="messageIsEmpty">Es wurde noch keine Nachricht geschrieben!</p>
                </div>

                <div class="field">
                  <div class="control">
                    <label class="checkbox">
                      <input type="checkbox"
                             v-model="user.acceptPrivacy"
                             v-on:change="validatePrivacy">
                      Die angegebenen Daten dürfen zur Kontaktaufnahme mit mir genutzt werden.
                    </label>
                  </div>
                  <p class="help is-danger" v-if="privacyNotAccepted">Den Datenschutzbestinmmmungen wurde nicht
                    zugestimmt.</p>
                </div>


                <div class="field is-grouped">
                  <div class="control">
                    <button type="submit" class="button is-link">Absenden</button>
                  </div>
                  <div class="control">
                    <a class="button is-text" v-on:click="closeForm">Abbrechen</a>
                  </div>
                </div>
              </form>

            </div>
        </section>
    </div>
</template>



<script>

export default {

  name: 'Kontaktformular',

  props: {
    showKontaktFormular: Boolean,
    name: String,
    empfaenger: String
  },
  methods: {
    closeForm() {
      this.$emit('show-kontaktformular', false);
    },
    validateName() {
      if (this.isBlank(this.user.name)) {
        this.nameIsEmpty = true;
      } else {
        this.nameIsEmpty = false;
      }
    },
    validateEmail() {
      if (this.isBlank(this.user.email)) {
        this.emailIsEmpty = true;
      } else {
        this.emailIsEmpty = false;
      }
    },
    validateMessage() {
      if (this.isBlank(this.user.message)) {
        this.messageIsEmpty = true;
      } else {
        this.messageIsEmpty = false;
      }
    },
    validatePrivacy() {
      if (!this.user.acceptPrivacy) {
        this.privacyNotAccepted = true;
      } else {
        this.privacyNotAccepted = false;
      }
    },
    validateForm() {
      this.validateName();
      this.validateEmail();
      this.validateMessage();
      this.validatePrivacy();
    },
    handleSubmit() {
      var self = this;

      self.validateForm();
      if (self.privacyNotAccepted || self.messageIsEmpty || self.emailIsEmpty || self.nameIsEmpty) {
        return;
      }

      this.$cgMailApi.post('/mail', this.user)
          .then(function () {
            self.mailSent = true;
            self.mailError = false;
            self.mailForm = false;
            self.mailSentMessage = 'Die E-Mail wurde verschickt!';
            self.user.email = '';
            self.user.name = '';
            self.user.message = '';
          })
          .catch(function (error) {
            self.mailSent = true;
            self.mailError = true;
            self.mailForm = false;
            self.mailSentMessage = 'Die E-Mail konnte nicht verschickt werden, bitte wende dich an kontakt@cg-karslruhe.de';
            console.log(error);
          });
    },
    isBlank(str) {
      return (!str || /^\s*$/.test(str));
    },
    navigationJsonLd() {
      var jsonLdBreadcrumb= {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "CG Karlsruhe",
          "item": "https://www.cg-karlsruhe.de"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Kontakt",
          "item": "https://www.cg-karlsruhe.de/kontakt"
        }]
      };
      return jsonLdBreadcrumb;
    }
  },
  mounted() {
  },
  data() {
    return {
      showMailForm: true,
      mailForm: false,
      mailError: false,
      mailSent: false,
      mailSentMessage: '',
      privacyNotAccepted: false,
      nameIsEmpty: false,
      emailIsEmpty: false,
      messageIsEmpty: false,
      user: {
        email: '',
        name: '',
        message: '',
        empfaenger: '',
        acceptPrivacy: false
      }
    }
  },

}
</script>

