<template>
    <div class="BAF">
      
        <section class="hero is-primary">
            <div class="hero-body">
                <div class="container">
                    <h1 class="title has-text-centered is-size-1" style="font-family: 'Kaushan Script';">
                        Besenfeldanschluss Freizeit
                    </h1>
                    <h2 class="subtitle is-italic has-text-centered">
                        Herzliche Einladung zur Besenfeldanschluss Freizeit
                    </h2>
                </div>
            </div>
        </section>

    <section class="section">
      <div class="container">
        <iframe src="https://form.cg-karlsruhe.de/BAF.html" title=Anmeldungsformular Jugendtag width=100% height=1800 border=none top=0 bottom=0></iframe>
        </div>
    </section>

  </div>
</template>

<script>

export default {
  name: 'BAF',
  components: {
      
  },
  data () {
    return {
    
    }
  },
  mounted () {
  },
}
</script>