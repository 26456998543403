<template>
    <div class="datenschutz">
      <script v-html=navigationJsonLd() type="application/ld+json"/>

        <section class="hero is-primary">
            <div class="hero-body">
                <div class="container">
                    <h1 class="title has-text-centered is-size-1" style="font-family: 'Kaushan Script';">
                        Datenschutz
                    </h1>
                    <h2 class="subtitle is-italic has-text-centered">
                        Er aber sprach: Selig sind, die das Wort Gottes hören und bewahren. Lukas 11,28
                    </h2>
                </div>
            </div>
        </section>


        <section class="section">
            <div class="container">

 <h3 class="title is-5" style="margin-top:40px">Datenschutz</h3>

                <p>
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
                    behandeln
                    Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
                    Datenschutzvorschriften sowie dieser Datenschutzerklärung.
                    <br/><br/>
                    Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich.
                    Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder
                    E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis.
                    Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.
                    <br/><br/>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per
                    E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
                    durch
                    Dritte ist nicht möglich.
                    <br/><br/>
                </p>

                <h3 class="title is-5">Datenschutzerklärung für die Nutzung von Matomo</h3>

                <p>
                    Dieses Angebot benutzt Matomo, eine Open-Source-Software zur statistischen Auswertung der
                    Nutzerzugriffe. Die IP-Adresse wird sofort nach der Verarbeitung und vor deren Speicherung
                    anonymisiert. Die Nutzung von Cookies wurde in Matomo deaktiviert.
                    <br/><br/>
                    <iframe
                            style="border: 0; height: 200px; width: 600px;"
                            src="https://matomo.cg-karlsruhe.de/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=fffaec&fontColor=&fontSize=12pt&fontFamily=Lato"
                    ></iframe>
                </p>

                <h3 class="title is-5" style="margin-top:40px">Google Fonts</h3>

                <p>
                    Diese Website bindet externe Schriften von Google Fonts ein. Google Fonts ist ein Dienst der
                    Firma Google Inc. (USA). Die Einbindung der Schriften erfolgt durch einen Serveraufruf bei
                    Google in den USA, wobei davon auszugehen ist, dass Anfragen durch Google gespeichert
                    werden.<br/>
                    Nähere Informationen dazu können Sie dem Google Fonts FAQ unter <a
                        href="https://developers.google.com/fonts/faq#what-does-using-the-google-fonts-api-mean-for-the-privacy-of-my-users">https://developers.google.com/fonts/faq#what-does-using-the-google-fonts-api-mean-for-the-privacy-of-my-users</a>
                    bzw. den Google Datenschutzhinweisen unter <a
                        href="https://www.google.de/intl/de/policies/">https://www.google.de/intl/de/policies/</a>
                    entnehmen.
                </p>

                <h3 class="title is-5" style="margin-top:40px">Server Log Files</h3>

                <p>
                    Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
                    Server-Log Files, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                    <br/><br/>
                    Browsertyp/Browserversion<br/>
                    verwendetes Betriebssystem<br/>
                    Referrer URL<br/>
                    Hostname des zugreifenden Rechners<br/>
                    Uhrzeit der Serveranfrage<br/>
                    <br/>
                    Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit
                    anderen Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich
                    zu
                    prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.
                </p>


                <h3 class="title is-5" style="margin-top:40px">Kontaktformular</h3>

                <p>
                    Wenn Sie uns per Kontakt- oder Anmeldeformular Anfragen zukommen lassen, werden Ihre Angaben
                    aus
                    dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung
                    der
                    Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
                    nicht
                    ohne Ihre Einwilligung weiter.

                    <br/><br/>
                    Quelle: <a href="http://e-recht24.de">e-recht24.de</a>
                </p>

            </div>
        </section>

    </div>
</template>


<script>


export default {
  components: {
  },
  methods: {
    navigationJsonLd() {
      var jsonLdBreadcrumb= {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "CG Karlsruhe",
          "item": "https://www.cg-karlsruhe.de"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Impressum",
          "item": "https://www.cg-karlsruhe.de/impressum"
        }]
      };
      return jsonLdBreadcrumb;
    }
  }
}
</script>

